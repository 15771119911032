<template>
	<div class="pmt-login">
		<v-card
			class="crd-login pa-5 ma-5"
			elevation="2"
			style="border-radius: 25px !important"
			width="450"
		>
			<div class="kominfo-logo d-flex justify-center align-center mt-15 mb-5">
				<img src="../../../assets/image/kominfo.png" alt="kominfo" />
			</div>
			<div class="text-center">
				<div>Direktorat Jendral</div>
				<div class="mb-2">Penyelenggaraan Pos dan Informatika</div>
				<div class="font-weight-bold mb-5">
					Dashboard Pusat Monitoring Telekomunikasi
				</div>
			</div>
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-row class="mt-10 pl-10 pr-10">
					<v-col xl="12" lg="12" md="12" sm="12" cols="12">
						<v-text-field
							v-model="username"
							:rules="rules.required"
							outlined
							single-line
							prepend-inner-icon="mdi-account"
							placeholder="Username"
						></v-text-field>
					</v-col>
					<v-col xl="12" lg="12" md="12" sm="12" cols="12">
						<v-text-field
							v-model="password"
							:rules="rules.required"
							:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
							:type="show1 ? 'text' : 'password'"
							prepend-inner-icon="mdi-lock"
							outlined
							single-line
							placeholder="Password"
							@click:append="show1 = !show1"
							@keyup.enter="signIn"
						></v-text-field>
					</v-col>
					<v-col xl="12" lg="12" md="12" sm="12" cols="12">
						<v-btn
							@click="signIn"
							width="100%"
							elevation="0"
							color="#169CD6"
							class="white--text pa-6"
						>
							Login
						</v-btn>
						<div class="text-center mt-10">
							<a class="grey--text" href="/auth/forgot-password">
								Forgot Password?
							</a>
						</div>
					</v-col>
					<v-col xl="4" lg="4" md="4" sm="4" xs="4">
						<img
							class="float-right"
							width="60%"
							src="../../../assets/image/image7.png"
							alt="image7"
						/>
					</v-col>
					<v-col class="text-center" xl="4" lg="4" md="4" sm="4" xs="4">
						<img src="../../../assets/image/moda.png" alt="moda" />
					</v-col>
					<v-col xl="4" lg="4" md="4" sm="4" xs="4">
						<img width="62%" src="../../../assets/image/image9.png" alt="image8" />
					</v-col>
				</v-row>
			</v-form>
		</v-card>
		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { AUTH_SIGNIN } from '../../../store/auth.module';
export default {
	data: () => ({
		username: null,
		show1: false,
		password: null,
		overlay: false,
		valid: true,
		rules: {
			required: [(v) => !!v || 'Field wajib diisi']
		}
	}),

	methods: {
		validate() {
			this.$refs.form.validate();
			return this.$refs.form.validate();
		},
		signIn() {
			if (!this.validate()) {
				return;
			}
			this.overlay = true;
			const data = {
				username: this.username,
				password: this.password
			};
			this.$store
				.dispatch(AUTH_SIGNIN, data)
				.then((result) => {
					this.overlay = false;
					if (!result.data.network_operator) {
						this.$swal({
							title: 'Gagal Login',
							text: 'Akun tidak terasosiasi dengan Network Operator manapun',
							showConfirmButton: true,
							confirmButtonColor: '#169cd6',
							icon: 'error'
						});
					} else if (result.data.role === 'network-operator') {
						this.$swal({
							title: 'Berhasil',
							text: 'Berhasil login ke dalam sistem.',
							timer: 1000,
							showConfirmButton: false,
							icon: 'success'
						}).then(() => this.$router.push({ name: 'Landing Page' }));
					} else {
						this.$swal({
							title: 'Gagal Login',
							text: 'Hanya bisa diakses oleh user Network Operator',
							showConfirmButton: true,
							confirmButtonColor: '#169cd6',
							icon: 'error'
						});
					}
				})
				.catch((error) => {
					this.overlay = false;
					this.$swal('Opps', error, 'error');
				});
		},
		toRegister() {
			this.$router.push({ name: 'Register' });
		}
	}
};
</script>

<style scoped>
.pmt-login {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
